import App from '@/ts/App'

export type FailedValidationResponse = {
    message?:string;
    errors?:Record<string, Array<string>>;
}

export function formInput<T extends HTMLElement>(form: HTMLFormElement, selector: string): T | null {
    const input = form.elements.namedItem(selector)

    return input ? input as T : null;
}

export function validateEmail(email: string):string|true {
    if(!email){
        return 'You have not entered an email address'
    }else if(!App.isValidEmail(email)){
        return 'You have not entered a valid email address'
    }

    return true
}

export function showErrors(input:Element, errors: Array<string>, container?:HTMLElement):void {
    if(!container){
        container = input.parentElement!;
    }

    if(errors.length){
        container.classList.add('invalid')
        const errorElem:HTMLElement = container.querySelector('span.error') || (():HTMLElement => {
            const elem = document.createElement('span')
            elem.className = 'error'
            input.parentElement!.appendChild(elem)
            return elem
        })()

        errorElem.textContent = ''
        errors.forEach((error, i) => {
            if(i > 0){
                errorElem.appendChild(document.createElement('br'))
            }
            errorElem.appendChild(document.createTextNode(error))
        })

        let onInput: EventListener

        onInput = () => {
            container.classList.remove('invalid')
            errorElem.textContent = ''
            input.removeEventListener('input', onInput)
        }

        input.addEventListener('input', onInput)
    }else{
        container.classList.remove('invalid')
        const errorElem:HTMLElement|null = container.querySelector('span.error')
        if(errorElem) {
            errorElem.textContent = ''
        }
    }
}

export function showErrorsFromResponse(inputs:Record<string, Element|null>, errors: Record<string, Array<string>>, container?:(input:Element) => HTMLElement) {
    Object.entries(inputs).forEach(([key, input]):void => {
        if(!input || !errors[key]){
            return
        }

        showErrors(input, errors[key], container ? container(input) : undefined)
    })
}